import React from "react"
import { Grid, makeStyles, Typography } from "@material-ui/core"
import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
  link: {
    fontFamily: "ヒラギノ丸ゴ ProN W4",
    color: "blue",
    textDecoration: "underline",
    textAlign: "center",
    fontSize: "1.4rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.6rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.8rem",
    },
  },
}))

const Nav = () => {
  const classes = useStyles()
  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-evenly"
      className={classes.root}
    >
      <Grid item>
        <Typography variant="body1" className={classes.link}>
          <Link to="/home">ホーム</Link>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" className={classes.link}>
          <Link to="/menu">MENU/価格表</Link>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" className={classes.link}>
          <a
            href="https://www.instagram.com/color_of_magician.bh/?hl=ja"
            target="_blank"
            rel="noopener noreferrer"
          >
            ギャラリー
          </a>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Nav
