import { Grid, makeStyles, Typography } from "@material-ui/core"
import { navigate } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { Instagram } from "@material-ui/icons"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "black",
  },
  iconsWrapper: {
    borderTop: "2px solid white",
    borderBottom: "2px solid white",
  },
  iconsContainer: {
    padding: "2rem",
    maxWidth: "80rem",
    marginRight: "auto",
    marginLeft: "auto",
  },
  iconContainer: {
    maxWidth: "10rem",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "12rem",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "14rem",
    },
  },
  icon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  link: {
    color: "white",
    marginTop: "1rem",
  },
  infoContainer: {
    padding: "2rem",
    maxWidth: "80rem",
    marginRight: "auto",
    marginLeft: "auto",
  },
  bottomContainer: {
    background: "linear-gradient(to bottom, red, maroon 20%, blue 100%)",
    height: "5rem",
    color: "white",
  },
}))

const Footer = () => {
  const classes = useStyles()
  const breakpoints = useBreakpoint()

  return (
    <footer>
      <Grid container direction="column" className={classes.root}>
        <div className={classes.iconsWrapper}>
          <Grid
            item
            container
            justifyContent="space-around"
            alignContent="center"
            className={classes.iconsContainer}
          >
            <Grid
              item
              xs={4}
              container
              justifyContent="flex-end"
              alignItems="center"
              direction="column"
              className={classes.iconContainer}
            >
              <Grid
                item
                className={classes.icon}
                onClick={() => navigate("/menu")}
              >
                {breakpoints.xs ? (
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/menu_icon.png"
                    alt="menu"
                    width={40}
                  />
                ) : breakpoints.sm ? (
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/menu_icon.png"
                    alt="menu"
                    width={50}
                  />
                ) : breakpoints.md ? (
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/menu_icon.png"
                    alt="menu"
                    width={60}
                  />
                ) : (
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/menu_icon.png"
                    alt="menu"
                    width={70}
                  />
                )}
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  className={classes.link}
                  style={{ fontFamily: "ヒラギノ丸ゴ ProN W4" }}
                >
                  <Link to="/menu">MENU/価格表</Link>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={4}
              container
              justifyContent="flex-end"
              alignItems="center"
              direction="column"
              className={classes.iconContainer}
            >
              <Grid
                item
                className={classes.icon}
                onClick={() => {
                  const url =
                    "https://www.instagram.com/color_of_magician.bh/?hl=ja"
                  if (typeof window !== `undefined`) {
                    window.open(url, "_blank")
                  }
                }}
              >
                {breakpoints.xs ? (
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/home_icon.jpg"
                    alt="home"
                    width={50}
                  />
                ) : breakpoints.sm ? (
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/home_icon.jpg"
                    alt="home"
                    width={60}
                  />
                ) : breakpoints.md ? (
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/home_icon.jpg"
                    alt="home"
                    width={70}
                  />
                ) : (
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/home_icon.jpg"
                    alt="home"
                    width={80}
                  />
                )}
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  className={classes.link}
                  style={{ fontFamily: "ヒラギノ丸ゴ ProN W4" }}
                >
                  <a
                    href="https://www.instagram.com/color_of_magician.bh/?hl=ja"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ギャラリー
                  </a>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={4}
              container
              justifyContent="flex-end"
              alignItems="center"
              direction="column"
              className={classes.iconContainer}
            >
              <Grid
                item
                className={classes.icon}
                onClick={() => navigate("/access")}
              >
                {breakpoints.xs ? (
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/car_icon.jpg"
                    alt="car"
                    width={70}
                  />
                ) : breakpoints.sm ? (
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/car_icon.jpg"
                    alt="car"
                    width={80}
                  />
                ) : breakpoints.md ? (
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/car_icon.jpg"
                    alt="car"
                    width={90}
                  />
                ) : (
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/car_icon.jpg"
                    alt="car"
                    width={100}
                  />
                )}
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  className={classes.link}
                  style={{ fontFamily: "ヒラギノ丸ゴ ProN W4" }}
                >
                  <Link to="/access">アクセス</Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Grid
          item
          container
          className={classes.infoContainer}
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={6}
            style={{ textAlign: "center", marginBottom: "1.2rem" }}
          >
            <a
              href="https://bighead-world.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              {breakpoints.xs ? (
                <StaticImage
                  placeholder="blurred"
                  src="../../images/bighead_txt.png"
                  alt="bighead"
                  width={200}
                />
              ) : breakpoints.sm ? (
                <StaticImage
                  placeholder="blurred"
                  src="../../images/bighead_txt.png"
                  alt="bighead"
                  width={300}
                />
              ) : breakpoints.md ? (
                <StaticImage
                  placeholder="blurred"
                  src="../../images/bighead_txt.png"
                  alt="bighead"
                  width={400}
                />
              ) : (
                <StaticImage
                  placeholder="blurred"
                  src="../../images/bighead_txt.png"
                  alt="bighead"
                  width={500}
                />
              )}
              <Typography
                style={{
                  color: "white",
                  textDecoration: "underline",
                  fontFamily:
                    "Hiragino Maru Gothic Pro, Hiragino Maru Gothic ProN, Meiryo, sans-serif",
                }}
                align="center"
              >
                ホームページはこちら
              </Typography>
            </a>
          </Grid>
          <Grid item container xs={12} sm={6} direction="column">
            <Grid item>
              <Typography
                variant="h4"
                align="right"
                style={{ fontFamily: "ヒラギノ丸ゴ ProN W4" }}
              >
                <span style={{ color: "white" }}>〒355-0032</span>
                <br />
                <span style={{ color: "white" }}>
                  埼玉県東松山市新宿町24-16
                </span>
                <br />
                <span style={{ color: "magenta" }}>営業時間：10:00~19:00</span>
                <br />
                <span style={{ color: "orange" }}>定休日：火曜/第３水曜</span>
                <br />
                <span style={{ color: "white" }}>ご予約優先/飛び込みOK！</span>
                <br />
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="flex-end"
              spacing={2}
            >
              <Grid item>
                {breakpoints.xs ? (
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/phone_icon.jpg"
                    alt="phone"
                    width={34}
                  />
                ) : breakpoints.sm ? (
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/phone_icon.jpg"
                    alt="phone"
                    width={44}
                  />
                ) : breakpoints.md ? (
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/phone_icon.jpg"
                    alt="phone"
                    width={54}
                  />
                ) : (
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/phone_icon.jpg"
                    alt="phone"
                    width={60}
                  />
                )}
              </Grid>
              <Grid item>
                <a href="tel:0493-24-5067">
                  <span
                    style={{
                      color: "cyan",
                      fontSize: "3.2rem",
                      textDecoration: "underline",
                    }}
                  >
                    0493-24-5067
                  </span>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          className={classes.bottomContainer}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item>
            <a
              href="https://www.instagram.com/color_of_magician.bh/?hl=ja"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram style={{ fontSize: "3rem" }} />
            </a>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  )
}
export default Footer
