import React from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { Grid, makeStyles, Typography } from "@material-ui/core"
import { Instagram } from "@material-ui/icons"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { isMobile } from "react-device-detect"

const OutlinePurple = styled.span`
  font-style: italic;
  display: inline-block;
  color: #ffffff; /* 文字の色 */
  letter-spacing: 4px; /* 文字間 */
  text-shadow: 2px 2px 1px #7f007f, -2px 2px 1px #7f007f, 2px -2px 1px #7f007f,
    -2px -2px 1px #7f007f, 2px 0px 1px #7f007f, 0px 2px 1px #7f007f,
    -2px 0px 1px #7f007f, 0px -2px 1px #7f007f; /* 文字の影 */
`

const useStyles = makeStyles(theme => ({
  headerTop: {
    background: "linear-gradient(to bottom, navy, maroon 60%)",
    padding: "1.2rem",
  },
  headerTxt: {
    color: "white",
    display: "inline",
    fontSize: "1.1rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.8rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.4rem",
    },
  },
}))

const Header = () => {
  const classes = useStyles()
  const breakpoints = useBreakpoint()

  return (
    <>
      <Grid container direction="column">
        <Grid
          item
          container
          className={classes.headerTop}
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid item style={{ textAlign: "center" }} xs={10}>
            <Typography variant="body1" className={classes.headerTxt}>
              <OutlinePurple>『カラーの魔術師』</OutlinePurple>
              は、
            </Typography>
            {breakpoints.xs ? (
              <StaticImage
                placeholder="blurred"
                src="../../images/bighead_txt.png"
                alt="BIGHEAD"
                height={16}
              />
            ) : breakpoints.sm ? (
              <StaticImage
                placeholder="blurred"
                src="../../images/bighead_txt.png"
                alt="BIGHEAD"
                height={18}
              />
            ) : breakpoints.md ? (
              <StaticImage
                placeholder="blurred"
                src="../../images/bighead_txt.png"
                alt="BIGHEAD"
                height={22}
              />
            ) : (
              <StaticImage
                placeholder="blurred"
                src="../../images/bighead_txt.png"
                alt="BIGHEAD"
                height={26}
              />
            )}
            <Typography variant="body1" className={classes.headerTxt}>
              における
              <br className="sp" />
              カラー施術コンセプトです。
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            <a
              href="https://www.instagram.com/color_of_magician.bh/?hl=ja"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram style={{ color: "white", fontSize: "3rem" }} />
            </a>
          </Grid>
        </Grid>
      </Grid>
      {isMobile ? (
        <StaticImage
          src="../../images/sp_header.png"
          alt="カラーの魔術師"
          placeholder="blurred"
          layout="fullWidth"
        />
      ) : (
        <StaticImage
          src="../../images/pc_header.png"
          alt="カラーの魔術師"
          placeholder="blurred"
          layout="fullWidth"
        />
      )}
    </>
  )
}

export default Header
