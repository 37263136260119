import { createTheme } from "@material-ui/core/styles"

const theme = createTheme({
  palette: {
    primary: {
      main: "#4C9B4B",
    },
  },
  typography: {
    h1: {
      fontSize: "4rem",
      fontWeight: 600,
      lineHeight: 1.5,
      fontFamily:
        "MS PMincho, HiraMinPro-W6, ヒラギノ丸ゴ ProN W4, Hiragino Maru Gothic ProN, 游ゴシック体, YuGothic, 游ゴシック Medium, Yu Gothic Medium, 游ゴシック, Yu Gothic, sans-serif",
    },
    h2: {
      fontSize: "3.2rem",
      fontWeight: 600,
      lineHeight: 1.5,
      fontFamily:
        "MS PMincho, HiraMinPro-W6, ヒラギノ丸ゴ ProN W4, Hiragino Maru Gothic ProN, 游ゴシック体, YuGothic, 游ゴシック Medium, Yu Gothic Medium, 游ゴシック, Yu Gothic, sans-serif",
    },
    h3: {
      fontSize: "2.2rem",
      fontWeight: 600,
      lineHeight: 1.5,
      fontFamily:
        "MS PMincho, HiraMinPro-W6, ヒラギノ丸ゴ ProN W4, Hiragino Maru Gothic ProN, 游ゴシック体, YuGothic, 游ゴシック Medium, Yu Gothic Medium, 游ゴシック, Yu Gothic, sans-serif",
    },
    body1: {
      fontSize: "1.4rem",
      fontWeight: 600,
      fontFamily:
        "MS PMincho, HiraMinPro-W6, ヒラギノ丸ゴ ProN W4, Hiragino Maru Gothic ProN, 游ゴシック体, YuGothic, 游ゴシック Medium, Yu Gothic Medium, 游ゴシック, Yu Gothic, sans-serif",
    },
    body2: {
      fontSize: "1.2rem",
      fontWeight: 600,
      fontFamily:
        "MS PMincho, HiraMinPro-W6, ヒラギノ丸ゴ ProN W4, Hiragino Maru Gothic ProN, 游ゴシック体, YuGothic, 游ゴシック Medium, Yu Gothic Medium, 游ゴシック, Yu Gothic, sans-serif",
    },
  },
})

export default theme
